
import React, { lazy, } from "react";

const ModelManager = lazy(() => import('~/views/lowcode/modelManager'))
const FormGenerater = lazy(() => import('~/views/lowcode/formGenerater'))

const Lowcode = [
    {
        path: '/modelManager', element: <ModelManager />,
    },
    {
        path: '/formGenerater', element: <FormGenerater />,
    },
]


export default Lowcode 